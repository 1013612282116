<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="4">
                <v-card style="height: 835px; overflow-y: auto;">
                    <v-card-title class="grey lighten-4 primary--text text-capitalize d-flex justify-space-between">
                        <div>
                            <v-icon color="primary">mdi-account-group</v-icon>
                            {{chatType}}
                        </div>
                        <v-menu
                        bottom
                        left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                            >
                                <v-icon color="primary">mdi-chevron-down</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="chatType = 'my friends'">
                                    <v-list-item-title>My Friends</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="chatType = 'trip chat'">
                                    <v-list-item-title>Trip Chats</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="chatType = 'agency chat'">
                                    <v-list-item-title>Agency Chat</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-title>
                    <v-list>
                        <v-list-item v-for="item in friends" :key="item.id" @click="startChat(item)">
                            <v-list-item-avatar>
                                <img :src="require(`@/assets/images/${item.image}`)">
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="item.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="item.desc"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
              <v-card>
                <v-card-title class="grey lighten-4 grey--text text-capitalize body-1">{{currentFriend.name}}</v-card-title>
                <v-card-text class="pa-3" id="messages-list" style="height: 700px; overflow-y: auto;">
                  <div v-if="currentMessages.length">
                    <div v-for="message in currentMessages" :key="message.id"
                    :class="message.type === 'sent' ? 'justify-end' : ''"
                    class="d-flex message-cont"
                    >
                      <v-card elevation="0" min-width="50%" max-width="80%" shaped class="body-1 my-3 pa-3 grey--text" :color="message.type === 'sent' ? 'grey lighten-4' : 'primary lighten-3'">
                        <div class="d-flex justify-space-between font-weight-medium">
                          <div>
                            <v-avatar size="25">
                              <img
                              :src="require(`@/assets/images/${currentFriend.image}`)"
                              :alt="currentFriend.name"
                              class="mb-3"
                              >
                            </v-avatar>
                            {{currentFriend.name}}
                          </div>
                          <div>{{message.time}}</div>
                        </div>
                        <v-card-text>
                          {{message.message}}
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-center body-1 font-weight-medium paragraph--text">Start a conversation with {{currentFriend.name}} now!</p>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn icon color="primary"><v-icon>mdi-image-multiple</v-icon></v-btn>
                  <v-btn icon color="primary"><v-icon>mdi-video</v-icon></v-btn>
                  <twemoji-picker
                  :emojiData="emojiDataAll"
                  :emojiGroups="emojiGroups"
                  :skinsSelection="false"
                  :searchEmojisFeat="true"
                  searchEmojiPlaceholder="Search here."
                  searchEmojiNotFound="Emojis not found."
                  isLoadingLabel="Loading..."
                  @emojiUnicodeAdded="addImoji"
                  ></twemoji-picker>
                  <v-text-field id="message-field" v-model="message" hide-details class="message-field" rounded filled color="grey">
                    <template class="mt-0" v-slot:append>
                      <v-btn @click="sendMessage" elevation="0" height="55.99" tile class="rounded-r-xl" color="primaryDark white--text px-5"><v-icon left>mdi-send</v-icon> Send</v-btn>
                    </template>
                  </v-text-field>
                </v-card-actions>
              </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { TwemojiPicker } from '@kevinfaguiar/vue-twemoji-picker'
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json'
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json'

export default {
  components: {
    'twemoji-picker': TwemojiPicker
  },
  data () {
    return {
      chatType: 'my friends',
      friends: [
        { id: 1, name: 'john doe', image: 'friends.png', desc: 'traveller, explorer' },
        { id: 2, name: 'jane doe', image: 'friends.png', desc: 'traveller, explorer' },
        { id: 3, name: 'ali adel', image: 'friends.png', desc: 'traveller, explorer' },
        { id: 4, name: 'noor hany', image: 'friends.png', desc: 'traveller, explorer' },
        { id: 5, name: 'alex ray', image: 'friends.png', desc: 'traveller, explorer' },
        { id: 6, name: 'jessica more', image: 'friends.png', desc: 'traveller, explorer' },
        { id: 7, name: 'hossam habib', image: 'friends.png', desc: 'traveller, explorer' },
        { id: 8, name: 'sara antony', image: 'friends.png', desc: 'traveller, explorer' }
      ],
      chats: [
        {
          id: 1,
          friendId: 1,
          messages: [
            { message: 'hello there', time: 'Aug 12th 23:20', type: 'sent' },
            { message: 'how you doing today ?', time: 'Aug 12th 23:21', type: 'sent' },
            { message: 'hi', time: 'Aug 12th 23:21', type: 'recieved' },
            { message: 'all is well, you ?', time: 'Aug 12th 23:22', type: 'recieved' },
            { message: 'gr8', time: 'Aug 12th 23:23', type: 'sent' },
            { message: 'have you checked the trip yet ?', time: 'Aug 12th 23:24', type: 'sent' },
            { message: 'oh yeah, it is amazing I have told my family about it and the are willing to join, so I think it would be great if they come', time: 'Aug 12th 23:27', type: 'recieved' },
            { message: 'this is great the more they are the funnier it gets!', time: 'Aug 12th 23:21', type: 'sent' }
          ]
        },
        {
          id: 2,
          friendId: 2,
          messages: [
            { message: 'hello there', time: 'Aug 12th 23:20', type: 'sent' },
            { message: 'how you doing today ?', time: 'Aug 12th 23:21', type: 'sent' },
            { message: 'hi', time: 'Aug 12th 23:21', type: 'recieved' },
            { message: 'all is well, you ?', time: 'Aug 12th 23:22', type: 'recieved' },
            { message: 'gr8', time: 'Aug 12th 23:23', type: 'sent' },
            { message: 'have you checked the trip yet ?', time: 'Aug 12th 23:24', type: 'sent' }
          ]
        },
        {
          id: 3,
          friendId: 3,
          messages: [
            { message: 'hello there', time: 'Aug 12th 23:20', type: 'sent' }
          ]
        },
        {
          id: 4,
          friendId: 4,
          messages: []
        },
        {
          id: 5,
          friendId: 5,
          messages: []
        },
        {
          id: 6,
          friendId: 6,
          messages: []
        },
        {
          id: 7,
          friendId: 7,
          messages: []
        },
        {
          id: 8,
          friendId: 8,
          messages: []
        }
      ],
      currentFriend: {},
      currentMessages: [],
      message: ''
    }
  },
  computed: {
    emojiDataAll () {
      return EmojiAllData
    },
    emojiGroups () {
      return EmojiGroups
    }
  },
  methods: {
    startChat (user) {
      this.currentFriend = user
      this.currentMessages = this.chats.find(chat => chat.friendId === user.id).messages
      setTimeout(() => {
        document.getElementById('messages-list').scrollTop = document.getElementById('messages-list').scrollHeight
      }, 0.2)
    },
    addImoji (emoji) {
      const position = document.getElementById('message-field').selectionStart
      this.message = [this.message.slice(0, position), emoji, this.message.slice(position)].join('')
    },
    sendMessage () {
      if (this.message) {
        this.currentMessages.push({
          message: this.message,
          time: new Date().toLocaleTimeString(),
          type: 'sent'
        })
        this.message = ''
        setTimeout(() => {
          document.getElementById('messages-list').scrollTop = document.getElementById('messages-list').scrollHeight
        }, 0.2)
      }
    }
  },
  mounted () {
    document.getElementById('messages-list').scrollTop = document.getElementById('messages-list').scrollHeight
    document.getElementById('message-field').addEventListener('keypress', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault()
        this.sendMessage()
      }
    })
  },
  created () {
    this.startChat(this.friends.find(friend => friend.id === this.$route.params.id || 1))
  }
}
</script>

<style>
.message-field.v-text-field {
  cursor: text;
}
.message-field.v-text-field .v-input__append-inner {
  padding: 0;
  margin: 0;
}
.message-field.v-text-field .v-input__slot {
  padding-right: 0;
}
</style>
